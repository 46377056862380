import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import Lightbulb from '../../svgs/lightbulb.svg'

const WorkingWithYourBuilderPage = () => {
  const intl = useIntl()
  return (
    <Layout
      view='buyer'
      breadcrumb={{
        text: <FormattedMessage id='buyerNav.dashboard' />,
        path: '/new-home-buyer/dashboard/'
      }}
      header={
        <>
          <FormattedMessage id='workingWithBuilder.headerAi' />{' '}
          <span style={{ fontWeight: `bold` }}>
            <FormattedMessage id='workingWithBuilder.headerAii' />
          </span>
        </>
      }
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'buyerNav.workingWith' })}
      />
      <Container>
        <Row>
          <Col>
            <h2>
              <span className='thin'>
                <FormattedMessage id='workingWithBuilder.headerBi' />
              </span>{' '}
              <FormattedMessage id='workingWithBuilder.headerBii' />
            </h2>
            <p>
              <FormattedMessage id='workingWithBuilder.contentA' />
            </p>
          </Col>
        </Row>
      </Container>

      <section className='fullWidthRow'>
        <Container>
          <Row align='end' style={{ marginBottom: `1rem` }}>
            <Col md={1}>
              <Lightbulb />
            </Col>

            <Col md={11}>
              <h2 style={{ marginBottom: `0` }}>
                <span className='thin'>
                  <FormattedMessage id='nav.tipsFor' />
                </span>
                <br />
                <FormattedMessage id='workingWithBuilder.headerC' />
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <ol className='largeNums'>
                <li>
                  <div>
                    <b>
                      <FormattedMessage id='workingWithBuilder.list01A_B' />
                    </b>
                    <br />
                    <FormattedMessage id='workingWithBuilder.list01A' />
                  </div>
                </li>
                <li>
                  <div>
                    <b>
                      <FormattedMessage id='workingWithBuilder.list01B_B' />
                    </b>
                    <br />
                    <FormattedMessage id='workingWithBuilder.list01B' />
                  </div>
                </li>
                <li>
                  <div>
                    <b>
                      <FormattedMessage id='workingWithBuilder.list01C_B' />
                    </b>
                    <br />
                    <FormattedMessage id='workingWithBuilder.list01C' />
                  </div>
                </li>
                <li>
                  <div>
                    <b>
                      <FormattedMessage id='workingWithBuilder.list01D_B' />
                    </b>
                    <br />
                    <FormattedMessage id='workingWithBuilder.list01D' />
                  </div>
                </li>
                <li>
                  <div>
                    <b>
                      <FormattedMessage id='workingWithBuilder.list01E_B' />
                    </b>
                    <br />
                    <FormattedMessage id='workingWithBuilder.list01E' />
                  </div>
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col>
            <h2>
              <span>
                <FormattedMessage id='workingWithBuilder.headerDi' />
              </span>{' '}
              <FormattedMessage id='workingWithBuilder.headerDii' />
            </h2>
            <p>
              <FormattedMessage id='workingWithBuilder.contentBi' />
            </p>
            <p>
              <FormattedMessage id='workingWithBuilder.contentBii' />{' '}
              <Link to='/licensing-compliance/complaints-process/'>
                <FormattedMessage id='workingWithBuilder.contentBiii' />
              </Link>
              <FormattedMessage id='workingWithBuilder.contentBiv' />
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default WorkingWithYourBuilderPage
